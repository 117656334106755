import { useMemo, Fragment } from 'react';
import Image from 'next/image';
import { getRoute } from '@utils';
import { useQuery } from '@apollo/client';
import Link from 'next/link';
import { currentTermsInfo, CurrentTermsResponse } from '@queries/terms/queries';
import {
  RootDiv,
  FooterContainer,
  RightsReservedContainer,
  TitleTypography,
  TextTypography,
  DivContainer,
  ContentDiv,
  DivGroupLeftContainer,
  DivGroupRightContainer,
  ImageContainer,
  IconContainer,
  RRSSIconContainer,
  IconGroupContainer,
  IconTextContainer,
} from './footer.styles';
import { useSegment } from '@hooks/useSegment';
import { useRouter } from 'next/router';

export function Footer() {
  const { data } = useQuery<CurrentTermsResponse>(currentTermsInfo('terms'));
  const { track } = useSegment();
  const { pathname } = useRouter();

  const INFOOPTIONS = useMemo(
    () => [
      {
        title: 'Zonas de despacho',
        route: getRoute('deliveryZones'),
      },
      {
        title: 'Info. Paciente',
        route: getRoute('regulatoryInfoGeneral'),
      },
      {
        title: 'Info. Toxicológica',
        route: getRoute('regulatoryInfoToxicology'),
      },
      {
        title: 'Desabastecimiento',
        openFile: () =>
          window.open(
            'https://d1zphfo9y2yvu9.cloudfront.net/docs/carta-de-desabastecimiento.pdf',
            '_blank',
          ),
      },
      {
        title: 'Regulación',
        route: getRoute('regulatoryInfoDecrees'),
      },
      {
        title: 'Términos y condiciones',
        openFile: () => window.open(data?.currentTerms?.url, '_blank'),
      },
      {
        title: 'Política de privacidad',
        openFile: () =>
          window.open(
            'https://d1zphfo9y2yvu9.cloudfront.net/docs/Poli%CC%81tica+de+Privacidad+Meki.pdf',
            '_blank',
          ),
      },
    ],
    [data?.currentTerms],
  );

  const ASSISTOPTIONS = useMemo(
    () => [
      {
        title: 'Consultas Químico',
        route: getRoute('pharmaceutical').toLink(),
      },
      {
        title: 'Devoluciones',
        route: getRoute('devolutions').toLink(),
      },
      {
        title: 'Sugerencias y reclamos',
        route: getRoute('suggestions').toLink(),
      },
    ],
    [],
  );

  return (
    <RootDiv>
      <FooterContainer>
        <DivGroupLeftContainer>
          <DivContainer>
            <ContentDiv>
              <TitleTypography variant="h2">¿NECESITAS AYUDA?</TitleTypography>
              <Link href="mailto:contacto@farmaciameki.cl" passHref>
                <IconTextContainer
                  onClick={() => {
                    void track('contact', {
                      to: 'email',
                    });
                  }}
                >
                  <IconContainer>
                    <Image
                      src="/assets/images/home/mailIcon.webp"
                      alt="email"
                      layout="fill"
                      objectFit="contain"
                    />
                  </IconContainer>
                  <TextTypography>contacto@farmaciameki.cl</TextTypography>
                </IconTextContainer>
              </Link>
              <Link
                href={`https://api.whatsapp.com/send?phone=56977212932&text=Hola!%20Les%20escribo%20porque%20quiero`}
                target="_blank"
                passHref
              >
                <IconTextContainer
                  onClick={() => {
                    void track('contact', {
                      to: 'phone',
                    });
                  }}
                >
                  <IconContainer>
                    <Image
                      src="/assets/images/home/whatsappIcon.webp"
                      alt="Whatsapp"
                      layout="fill"
                      objectFit="contain"
                    />
                  </IconContainer>
                  <TextTypography>+56 9 7721 2932</TextTypography>
                </IconTextContainer>
              </Link>
            </ContentDiv>
            <ContentDiv>
              <TitleTypography variant="h2">¿DÓNDE ESTAMOS?</TitleTypography>
              <IconTextContainer
                onClick={() => {
                  window.open(
                    'https://www.google.com/maps/place/Meki+-+Farmacia+a+domicilio/@-33.3933837,-70.6239762,17.18z/data=!4m6!3m5!1s0x9662c7cd03b3360d:0x53359a5be494b0ae!8m2!3d-33.3925354!4d-70.6239333!16s%2Fg%2F11rrvtrsrm',
                    '_blank',
                  );
                }}
              >
                <IconContainer>
                  <Image
                    src="/assets/images/home/locationIcon.webp"
                    alt="Location"
                    layout="fill"
                    objectFit="contain"
                  />
                </IconContainer>
                <Link
                  href="https://www.google.com/maps/place/Meki+-+Farmacia+a+domicilio/@-33.3933837,-70.6239762,17.18z/data=!4m6!3m5!1s0x9662c7cd03b3360d:0x53359a5be494b0ae!8m2!3d-33.3925354!4d-70.6239333!16s%2Fg%2F11rrvtrsrm"
                  target={'_blank'}
                  passHref
                >
                  <TextTypography>
                    Av. El Salto 4001・Local 8, Huechuraba, RM
                  </TextTypography>
                </Link>
              </IconTextContainer>
            </ContentDiv>
          </DivContainer>
          <DivContainer>
            <ContentDiv>
              <TitleTypography variant="h2">ASISTENCIA</TitleTypography>
              {ASSISTOPTIONS.map((option) => (
                <Fragment key={option.title}>
                  <Link {...option.route} passHref>
                    <TextTypography>{option.title}</TextTypography>
                  </Link>
                </Fragment>
              ))}
            </ContentDiv>
            <ContentDiv>
              <TitleTypography>REDES SOCIALES</TitleTypography>
              <IconGroupContainer>
                <RRSSIconContainer
                  onClick={() => {
                    void track('rrss', {
                      to: 'instagram',
                    });
                  }}
                >
                  <Link
                    href="https://www.instagram.com/farmaciameki/"
                    target={'_blank'}
                    passHref
                  >
                    <Image
                      layout="fill"
                      src="/assets/logos/instagramLogo.webp"
                      alt="Instagram"
                      objectFit="contain"
                    />
                  </Link>
                </RRSSIconContainer>
                <RRSSIconContainer
                  onClick={() => {
                    void track('rrss', {
                      to: 'facebook',
                    });
                  }}
                >
                  <Link
                    href={'https://www.facebook.com/farmaciameki/'}
                    target={'_blank'}
                    passHref
                  >
                    <Image
                      layout="fill"
                      src="/assets/logos/facebookLogo.webp"
                      alt="Facebook"
                      objectFit="contain"
                    />
                  </Link>
                </RRSSIconContainer>
              </IconGroupContainer>
            </ContentDiv>
          </DivContainer>
        </DivGroupLeftContainer>

        <DivGroupRightContainer>
          <DivContainer>
            <ContentDiv>
              <TitleTypography variant="h2">INFORMACIÓN</TitleTypography>
              {INFOOPTIONS.map((option) => (
                <Fragment key={option.title}>
                  {option.route ? (
                    <Link {...option.route?.toModalLink(pathname)}>
                      <TextTypography>{option.title}</TextTypography>
                    </Link>
                  ) : (
                    <TextTypography onClick={option.openFile}>
                      {option.title}
                    </TextTypography>
                  )}
                </Fragment>
              ))}
            </ContentDiv>
          </DivContainer>

          <DivContainer>
            <ImageContainer>
              <Image
                layout="fill"
                src="/assets/images/home/medbag-and-pills.webp"
                alt="Meki"
                objectFit="contain"
              />
            </ImageContainer>
          </DivContainer>
        </DivGroupRightContainer>
      </FooterContainer>
      <RightsReservedContainer>
        <TextTypography>Meki. Todos los derechos reservados</TextTypography>
      </RightsReservedContainer>
    </RootDiv>
  );
}
