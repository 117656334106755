import { Typography } from '@mui/material';
import { styled } from '@mui/system';

const RootDiv = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#004C8C',
});

const FooterContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: '8px 30px',
  '@media (min-width: 768px)': {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '30px 60px',
  },
  '@media (min-width: 992px)': {
    padding: '60px 40px',
  },
});

const DivGroupLeftContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  '@media (min-width: 1100px)': {
    flexDirection: 'row',
    margin: '0px 40px',
  },
});

const DivGroupRightContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  '@media (min-width: 1100px)': {
    flexDirection: 'row',
    margin: '0px 40px',
  },
});

const DivContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginRight: '40px',
});

const ContentDiv = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const RightsReservedContainer = styled('div')({
  textAlign: 'center',
  color: '#FFFFFF',
  padding: '17px 0px',
});

const TitleTypography = styled(Typography)({
  fontFamily: 'Poppins',
  fontSize: '20px',
  lineHeight: '30px',
  letterSpacing: '0.15px',
  fontWeight: 600,
  color: '#FFFFFF',
  padding: '12px 0px',
  marginTop: '30px',
});

const TextTypography = styled(Typography)({
  fontFamily: 'Poppins',
  fontSize: '17px',
  lineHeight: '25.5px',
  letterSpacing: '0.15px',
  fontWeight: 400,
  color: '#FFFFFF',
  paddingBottom: '10px',
  cursor: 'pointer',
});

const ImageContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  height: '190px',
  width: '191px',
  marginTop: '-300px',
  marginLeft: '50%',
  zIndex: 1,
  [theme.breakpoints.down(340)]: {
    display: 'none',
  },
  [theme.breakpoints.up(768)]: {
    position: 'relative',
    height: '243px',
    width: '241px',
    marginTop: '60px',
    marginLeft: 0,
  },
  [theme.breakpoints.up(992)]: {
    position: 'relative',
    height: '243px',
    width: '241px',
    marginTop: '90px',
  },
  [theme.breakpoints.down(360)]: {
    height: '150px',
  },
}));

const IconContainer = styled('div')({
  display: 'flex',
  position: 'relative',
  width: '24px',
  height: '24px',
  marginRight: '8px',
  cursor: 'pointer',
});

const RRSSIconContainer = styled('div')({
  display: 'flex',
  position: 'relative',
  width: '32px',
  height: '32px',
  marginRight: '15px',
  cursor: 'pointer',
  marginTop: '-3px',
});

const IconGroupContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
});

const IconTextContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
});

export {
  RootDiv,
  FooterContainer,
  RightsReservedContainer,
  TitleTypography,
  TextTypography,
  DivGroupLeftContainer,
  DivGroupRightContainer,
  DivContainer,
  ContentDiv,
  ImageContainer,
  IconContainer,
  RRSSIconContainer,
  IconGroupContainer,
  IconTextContainer,
};
